<template>
    <block>
        <router-view></router-view>
    </block>
</template>

<script>
    import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
    import { useStore } from 'vuex'
    export default {
        props: {
            entityType: {
                type: String,
                default: 'default'
            }
        },
        setup: function (props) {
            let {asyncOps, asyncOpsReady} = asyncOperations(props, useStore());

            return {asyncOps, asyncOpsReady}
        },
        data: function () {
            return {

            };
        }

    }
</script>

<style scoped lang="scss">

</style>
